var PopUp = (function() {
	// ---
	// vars
	var NAME = 'popup';
	// var VERSION = '1.0';

	var Selector = {
		POPUP: '.js-' + NAME,
		SHOW: '.js-' + NAME + '-show',
		HIDE: '.js-' + NAME + '-hide'
	};

	// ---
    // functions
    /**
      * shows popup
      * called by: 'js-popup-show'
	  *
      * @param {object} 	- element 
      * @param {callback} 	- function
    */
	var show = function(el, callback) {
		var popup =  jQuery(el).attr('data-popup-href');
		toggleVisibility(popup);

		if (callback) return callback();
	};

    /**
      * hides popup
      * called by: 'js-popup-hide'
	  *
      * @param {string} 	- popup id with #
      * @param {callback} 	- function
    */
	var hide = function(el, callback) {
		var popup = jQuery(el).closest(Selector.POPUP);
		toggleVisibility(popup);

		if (callback) return callback();
	};

    /**
      * shows popup
	  *
      * @param {string} 	- popup id with #
      * @param {callback} 	- function
    */
	var showWho = function(id, callback) {
		var popup = jQuery(id);
		toggleVisibility(popup);

		if (callback) return callback();
    };
    
    /**
      * hides popup
      *
      * @param {string} 	- popup id
      * @param {callback} 	- function
    */
    var hideWho = function(id, callback) {
		var popup = jQuery(id);
		toggleVisibility(popup);

		if (callback) return callback();
	};

	var toggleVisibility = function(el) {
		if (jQuery(el).attr('aria-hidden') === 'false') {
			jQuery(el).attr('aria-hidden', 'true');
		} else {
			jQuery(el).attr('aria-hidden', 'false');
		}
	};

	// ---
	// listeners
	// show
	jQuery(document).on('click', Selector.SHOW, function(e) {
		show(this);
		e.preventDefault();
	});

	// hide
	jQuery(document).on('click', Selector.HIDE, function(e) {
		hide(this);
		e.preventDefault();
	});

	return { showWho: showWho, hideWho: hideWho };
})();
