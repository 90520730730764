// navigation

// TODO remetre ajax d'origine
var deskNav = jQuery( '#js-nav__desktop' );

deskNav.find(".nav__item--2").each(function() {
	var 
		$listNav = jQuery(this).find(".nav__level--3"),
		$listNavChildres = $listNav.find("li").length;

	if ($listNav.length) {
		jQuery(this).find(".nav__link--2").addClass("nav__link--title");
	}

	if ($listNavChildres > 10) {
		$listNav.find( 'ul' ).eq(0).addClass("nav__columnezid");
	}
});

// ------------------
// nav dropdown
var topNavItem = deskNav.find( '.nav__link--1' ),
    showClass = 'show';

topNavItem.on( 'click', function(e) {
    // remove show from siblings
    jQuery(this).parent().siblings().each(function() {
        jQuery(this).find( '.' + showClass ).removeClass( showClass );
    });

    // check if there is dropdown
    var topNavItemDropdown = jQuery(this).next();

    if (jQuery(this).next().length) {
        topNavItemDropdown.toggleClass( showClass );
        // prevent
        e.preventDefault();
    }
});

deskNav.find( 'a' ).on( 'click', function(event) {
    event.stopPropagation();
});

jQuery(document).on( 'click', function(event) {
    deskNav.find( '.show' ).siblings( 'a' ).trigger('click');
});

// ---
// search/popup button
jQuery(function() {

    // ---
    // search mobile
    var btnSearch = jQuery( '#js-toggle-search' ),
        heaSearch = jQuery( '#js-search' ),
        isShown = 'db',
        isHidden = 'dn';

    btnSearch.on( 'click', function(e) {
        // show/hide search
        if ( heaSearch.hasClass( isShown ) ) {
            hideMobileSearch();
        } else {
            showMobileSearch();
        }

        // avoid click
        e.preventDefault();
    });

    // close search if user clicks on somewhere else in the document
    jQuery(document).on( 'click' , function() {
        // search button
        if ( heaSearch.hasClass( isShown ) ) {
            hideMobileSearch();
        }
    });

    // avoid closing search when clicking on search or buttons
    heaSearch.click(function(e) {
        e.stopPropagation(); // This is the preferred method.
    });

    btnSearch.click(function(e) {
        e.stopPropagation(); // This is the preferred method.
    });

    // show 
    function showMobileSearch() {
        // switch icons
        btnSearch.find( 'svg' ).toggleClass( isHidden );

        // find search and show
        heaSearch.addClass( isShown );
        
        // focus search
        setTimeout(function() {
            heaSearch.find( '.search-field' ).focus();
        }, 100);
    }

    // hide
    function hideMobileSearch() {
        // switch icons
        btnSearch.find( 'svg' ).toggleClass( isHidden );

        // find search and hide
        heaSearch.removeClass( isShown );
    }

});

// ---
// detect browser
navigator.sayswho= (function(){
    var ua= navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
})();

var $browser = "is-" + navigator.sayswho.toLowerCase();

// detect system
if (navigator.userAgent.indexOf('Mac OS X') != -1) {
    jQuery("body").addClass("is-mac " + $browser);
} else {
    jQuery("body").addClass("is-pc " + $browser);
}

// ---
// header banner close
var headerBanner = jQuery('.header-banner');

headerBanner.find('a[href*="jQuery"]').on('click', function(e) {
    headerBanner.remove();
    e.preventDefault();
});