jQuery(function () {
    var cache = {},
        lastXhr;
    
    var headerAutoCompleteClass = {
        ISLOADING: 'is-loading'
    }

    var headerAutoCompleteSelector = {
        PLACEHOLDERDROPDOWN: '.js-autocomplete--loading',
        SEARCHDROPDOWN: '.js-autocomplete--header-search'
    }

    jQuery.ui.autocomplete.prototype._renderItem = function (ul, item) {
        var term = this.term.split(" ").join("|");
        var re = new RegExp("(" + term + ")", "gi");
        var t = item.label.replace(re, "<strong>$1</strong>");
        if (item.image !== undefined) {
            var itemHTML =
                '<a><span class="acImage">' +
                item.image +
                '</span><span class="acName">' +
                t +
                '</span><br class="clearBoth" /></a>';
        } else {
            var itemHTML = "<a>" + t + "</a>";
        }
        return jQuery("<li></li>")
            .data("item.autocomplete", item)
            .append(itemHTML)
            .appendTo(ul);
    };

    initializeAjaxSearchSuggest(
        'form[name="add_to_cart"].cart__entry',
        "shopping_cart_add"
    );

    // add if form exist

    initializeAjaxSearchSuggest(
        'form[name="search-add"]:first',
        "wishlist_add"
    );

    initializeAjaxSearchSuggest(
        'form[name="quick_find_header"]:first',
        "search_header"
    );

    function initializeAjaxSearchSuggest(formSelector, from_form) {
        var headerAutoCompleteClass = '';
        var searchInput = jQuery(formSelector + ' input[name="s"]');

        if(isSearchHeader(from_form)) {
            insertSearchPlaceholderLoading(searchInput);
            headerAutoCompleteClass = 'autocomplete--header-search js-autocomplete--header-search';
        }

        searchInput.autocomplete({
            classes: {
                "ui-autocomplete":
                    "autocomplete " + headerAutoCompleteClass,
            },
            minLength: 3,
            autoFocus: false,
            dataType: "json",
            source: function (request, response) {
                if(isSearchHeader(from_form)) {
                    hasSearchResults() ? showSearchLoading(headerAutoCompleteSelector.SEARCHDROPDOWN) : showSearchLoading(headerAutoCompleteSelector.PLACEHOLDERDROPDOWN);
                }

                var term = request.term;

                if (term in cache) {
                    response(cache[term]);
                    return;
                }
                lastXhr = jQuery.getJSON(
                    "/search-product",
                    jQuery(formSelector).serialize(),
                    function (data, status, xhr) {
                        cache[term] = data;
                        if (xhr === lastXhr) {
                            response(
                                jQuery.map(data, function (item) {
                                    return {
                                        value: item.value,
                                        image: item.image,
                                        products_id: item.products_id,
                                        url: item.url,
                                    };
                                })
                            );
                        }
                    }
                );
            },
            select: function (event, ui) {
                event.preventDefault();
                if (ui.item) {
                    switch (ui.item.value) {
                        case as_no_result:
                            return false;
                            break;
                        // product link insted of research
                        default:
                            if (from_form == "shopping_cart_add") {
                                $("input[name=pid]").val(ui.item.products_id);
                                $('form[name="add_to_cart"] input[name=s]').val(
                                    ""
                                );
                                $(
                                    'form[name="add_to_cart"].cart__entry'
                                ).submit();
                            } else if (from_form == "wishlist_add") {
                                $.blockUI({
                                    message: "Adding to your Wishlist...",
                                });
                                $('form[name="search-add"] input[name=s]').val(
                                    ""
                                );
                                form = $("form[name=search-add]");
                                url =
                                    form.attr("action") +
                                    "/" +
                                    ui.item.products_id;
                                datapost = form.serialize();
                                $.ajax({
                                    type: "POST",
                                    url: url,
                                    data: datapost,

                                    statusCode: {
                                        408: function (response) {
                                            //Token Expired
                                            if (
                                                typeof response.errors ==
                                                "string"
                                            ) {
                                                $.blockUI({
                                                    message: response.errors,
                                                });
                                            }

                                            location.reload();
                                        },
                                    },
                                    success: function (response) {
                                        if (response.success == false) {
                                            $.blockUI({
                                                message: "An error occured",
                                            });
                                            input.val(response.name);
                                            setTimeout($.unblockUI, 1700);
                                        } else {
                                            $.blockUI({
                                                message:
                                                    "The product has been added to your Wishlist...",
                                            });
                                            $("tbody").append(response);
                                            setTimeout($.unblockUI, 1700);
                                            // refresh product
                                        }
                                    },
                                });
                            } else {
                                window.location.href = ui.item.url;
                            }
                            break;
                    }
                }
            },
            create: function (event, ui) {
                var ajaxSearchHeight = jQuery(formSelector).outerHeight(true);

                var ajaxSearchFontSize = jQuery(
                    formSelector + ' input[name="s"]'
                ).css("font-size");
            },
            open: function (event, ui) {
                hideSearchLoading();

                if ($(".help-inline.fast-add").length > 0) {
                    $(".help-inline.fast-add").remove();
                }
                var ajaxSearchWidth =
                    jQuery(formSelector).outerWidth(false) - 2; // subtract borders

                jQuery("ul.ui-autocomplete").css("width", ajaxSearchWidth);
            },
            close: function (event, ui) {
                hideSearchLoading();
                clearSearchResults();
            },
        });
    }

    /**
     * Detects if search is the header search to perform actions related to the header search only
     * 
     * @param {string} form 
     * @returns {boolean}
     */
    function isSearchHeader(form) {
        return form === 'search_header';
    }

    /**
     * Inserts loading placeholder
     * 
     * @param {object} el 
     */
    function insertSearchPlaceholderLoading(el) {
        jQuery(el).after(
            '<div class="autocomplete autocomplete--loading js-autocomplete--loading" aria-hidden="true"></div>'
        );
    }

    /**
     * Detects if search drodpown has any item in it. 
     * "No results found" is also counted
     * 
     * @returns {boolean}
     */
    function hasSearchResults() {
        return jQuery(headerAutoCompleteSelector.SEARCHDROPDOWN).children().length > 0;
    }

    /**
     * Shows loading in dropdown
     * 
     * @param {object} el
     */
    function showSearchLoading(el) {
        jQuery(el).addClass(headerAutoCompleteClass.ISLOADING);
    }

    /**
     * Hides loading in dropdown by removing is loading class from all elements
     * 
    */
    function hideSearchLoading() {
        jQuery(headerAutoCompleteSelector.PLACEHOLDERDROPDOWN).removeClass(headerAutoCompleteClass.ISLOADING);
        jQuery(headerAutoCompleteSelector.SEARCHDROPDOWN).removeClass(headerAutoCompleteClass.ISLOADING);
    }

    /**
     * Clears any result when search is closed
     * Necessary because to show the loading placeholder
     */
    function clearSearchResults() {
        jQuery(headerAutoCompleteSelector.SEARCHDROPDOWN).html('');
    }
});
