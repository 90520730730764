jQuery(function() {
    var subsForm = jQuery( 'form[name="subscribe_newsletter"]' );
        
    subsForm.on("submit", function(e) {
        // clear messages
        subsFormclearMessages();
        // disable form class
        subsForm.addClass( 'disabled' );

        // avoid user submits more than once
        if(subsForm.hasClass( 'disabled' )) {
            $.ajax({
                url: jQuery(this).attr("action"),
                data: jQuery(this).serialize(),
                type: "POST",
                success: function(data) {
                    if(data.success === false) {
                        // show error message
                        subsForm.append( '<span class="help-block form-error">' + data.message + '</span>' );
                    } else {
                        // reset form and show success message
                        subsForm[0].reset();
                        subsForm.append( '<span class="help-block form-success">' + data.message + '</span>' );
                    }
                    subsForm.removeClass( 'disabled' );
                }
            });
        }
        
        e.preventDefault();
    });

    // remove messages
    function subsFormclearMessages() {
        subsForm.find( '.help-block' ).remove();
    }
});
