jQuery(function () {
    // elements
    var flyoutBody    = jQuery( 'body' ),
        flyoutHtml    = jQuery( 'html' ),
        flyoutTrigger = jQuery( '#js-open-flyout' ),
        flyout        = jQuery( '#js-flyout' ),
        flyoutNavLink = flyout.find( '[class^="nav__link"]' ),
        flyoutNavBank = jQuery( '.sub__btn__back' ),
        flyoutOverlay = jQuery( '#js-flyout__overlay' );

    // make it visible after dom is completed loaded
    flyout.addClass( 'is-visible' );

    // css classes
    var isSlided    = 'is-slided',
        noOverflow  = 'is-ovhidden',
        isClicked   = 'is-clicked';

    // show/hide flyout
    flyoutTrigger.on( 'click', function(e) {
        // events
        e.preventDefault();
        flyoutTrigger.toggleClass( isClicked );
        flyoutBody.toggleClass( noOverflow );
        flyoutHtml.toggleClass( noOverflow );
        flyout.toggleClass( isSlided );
    });

    // flyout should be hidden automatically when going to desktop view
    jQuery(window).on( 'resize', function() {
        // 1240 a css value in components/_nav.scss, if you change there, you will need to change here too
        if (jQuery(this).width() > 1240) {
            if (flyoutTrigger.hasClass( isClicked )) {
                flyoutTrigger.trigger( 'click' );
            }
        }
    });

    // show flyout subs
    // links
    flyoutNavLink.on( 'click', function(e) {
        var flyoutSub = jQuery(this).next();
        // events
        if(jQuery(this).next().length) {
            e.preventDefault();
            flyoutSub.addClass( isSlided );
            flyoutSub.css('height', jQuery(window).height());
        }
    });

    // hide flyout subs
    // back button
    flyoutNavBank.on( 'click', function() {
        // events
        jQuery(this).closest( '.' + isSlided ).removeClass( isSlided );
    });

    // hide flyout
    // overlay click 
    flyoutOverlay.on( 'click', function() {
        // events
        flyoutTrigger.trigger( 'click' );
    }); 

});