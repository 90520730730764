jQuery(function() {
  var onClass = "show";

  jQuery(
    ".form-group--float-label input, .form-group--float-label select, .form-group--float-label textarea"
  ).each(function() {
    if (
      jQuery(this).attr("type") == "radio" ||
      jQuery(this).attr("type") == "checkbox" ||
      jQuery(this).attr('type') == "date"
    ) {
      // do nothing
    } else {
      jQuery(this)
        .bind("checkval", function() {
          moveFloatLabelUpDown(this, onClass);
        })
        .on("keyup", function() {
          jQuery(this).trigger("checkval");
        })
        .on("blur", function() {
          moveFloatLabelUpDown(this, onClass);
        })
        .trigger("checkval");
    }
  });

  jQuery("body")
    .on("change", ".form-group--float-label select", function(e) {
      var $this = jQuery(e.target);

      moveFloatLabelUpDown(this, onClass);

      $this.trigger("checkval");
    })
    .change();
});

function moveFloatLabelUpDown(el, labelClass) {
  var el = jQuery(el),
    elContainer = el.parents(".form-group--float-label");
  if (el.val() !== "") {
    elContainer.addClass(labelClass);
  } else {
    elContainer.removeClass(labelClass);
  }

  if (el.is("select")) {
    if (el.find(":selected").text() === "") {
      elContainer.removeClass(labelClass);
    }
  }
}

function moveFloatLabelUp(el, labelClass) {
  var el = jQuery(el),
    elContainer = el.parents(".form-group--float-label");

  elContainer.addClass(labelClass);
}
